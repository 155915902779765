<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>

<h2 class="tangy-foreground-secondary">{{'Published Surveys'|translate}}</h2>
<mat-list class="drag-list">
  <mat-list-item class="drag-item" *ngFor="let form of publishedSurveys; let index=index">
    <span>{{index+1}}</span>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span class="tangy-spacer" [innerHTML]="form.title|unsanitizeHtml"></span>

    <span *appHasAPermission="let i;group:group._id; permission:'can_manage_forms'">
      <button mat-icon-button (click)="unPublishSurvey(form.id)">
        <i class="material-icons mat-32 tangy-location-list-icon">unpublished</i>
    </button>
    </span>

    <span >{{form.updatedOn|date :'medium'}}
    </span>
    <span class="link"><a href="/releases/prod/online-survey-apps/{{group._id}}/{{form.id}}/index.html" target="_new"> <i class="material-icons mat-32 tangy-location-list-icon">link</i>link</a></span>
  </mat-list-item>
</mat-list>


<h2 class="tangy-foreground-secondary">{{'Unpublished Surveys'|translate}}</h2>
<mat-list class="drag-list">
  <mat-list-item class="drag-item" *ngFor="let form of unPublishedSurveys; let index=index">
    <span>{{index+1}}</span>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span class="tangy-spacer" [innerHTML]="form.title|unsanitizeHtml"></span>

    <span *appHasAPermission="let i;group:group._id; permission:'can_manage_forms'">
      <button mat-icon-button (click)="publishSurvey(form.id, form.title)">
        <i class="material-icons mat-32 tangy-location-list-icon">published_with_changes</i>
      </button>
    </span>

    <span >{{form.updatedOn|date :'medium'}}
    </span>
  </mat-list-item>
</mat-list>
