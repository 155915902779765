<h1>
    {{'New Group'|translate}}
</h1>
<form novalidate (ngSubmit)="createGroup()" #newGroupForm="ngForm">
    <mat-form-field>
        <input
            name="groupNameInput"
            [(ngModel)]="groupName"
            #groupNameInput="ngModel"
            matInput
            placeholder="{{'Group Name'|translate}}"
            required
        >
        <mat-error
            *ngIf="(groupNameInput.invalid||groupNameInput.errors) && (groupNameInput.dirty || groupNameInput.touched)"
        >
            {{'This Field is Required'|translate}}
        </mat-error>
    </mat-form-field>
    <br>
    <br>
    <button
        [disabled]="newGroupForm.invalid"
        mat-raised-button color="warn"
        type="submit">
        {{'Submit'|translate}}
    </button>
</form>
